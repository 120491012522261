import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/authModule'
import shared from './modules/sharedModule'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    shared,
  }
})
