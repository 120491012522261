<template>
  <div id="app">
    <b-overlay
      :show="loading"
      rounded="sm"
      no-wrap
      fixed
      spinner-variant="primary"
      z-index="999999"
    >
    </b-overlay>
    <div v-if="authenticated">
      <app-header></app-header>
      <div class="app-body">
        <b-container fluid>
          <router-view :key="$route.fullPath" />
        </b-container>
      </div>
    </div>
    <div v-else>
      <login></login>
    </div>
  </div>
</template>
<script>
import Login from "@/components/Login.vue";
import AppHeader from "@/views/_Header.vue";

export default {
  created() {
    this.$store.dispatch("auth/receiveUser");

  },
  components: {
    Login,
    AppHeader,
  },
  watch: {
    loading(val) {
      console.log("globalLoading", val);
    },
  },
  computed: {
    authenticated() {
      return this.$store.getters["auth/authenticated"];
    },
    loading() {
      return this.$store.getters["shared/loading"];
    },
  },
};
</script>

<style lang="scss">
</style>
