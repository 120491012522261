import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index';
import UnAuthorized from '@/views/UnAuthorized.vue'
//import Home from '../views/event/Index.vue' 
Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Home',
  redirect: '/myEvent'
  //component: Home
},
{
  path: '/unauthorized',
  name: 'UnAuthorized',
  component: UnAuthorized,
},
{
  path: '/about',
  name: 'About',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
},
{
  path: '/event',
  name: 'Event',
  component: () => import('../views/event/Index.vue'),
  props: () => ({ type: 0 })
},
{
  path: '/myEvent',
  name: 'MyEvent',
  component: () => import('../views/event/Index.vue'),
  props: () => ({ type: 1 })
},
{
  path: '/changePassword',
  name: 'ChangePassword',
  component: () => import('../views/ChangePassword.vue')
},
{
  path: '/vod',
  name: 'Vod',
  component: () => import('../views/event/Vod.vue'),
  meta: {
    authorize: [1]
  }
  //props: (route) => ({ streamId: Number(route.params.streamId), eventId: Number(route.params.eventId) })
},
{
  path: '/event/:type/:eventId',
  name: 'EventDetails',
  component: () => import('../views/event/Details.vue'),
  //props: true
  props: (route) => ({ type: Number(route.params.type), eventId: Number(route.params.eventId) })
}
]

const router = new VueRouter({
  //mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const authenticated = store.getters["auth/authenticated"]

  if (authenticated) {
    let currentUser = store.getters["auth/user"]

    let isValid = true;
    const {
      authorize
    } = to.meta;
    if (authorize) {
      if (authorize.includes(1)) {

        if (!currentUser.isShowVod) {
          isValid = false;
        }
      }


    }

    if (isValid == false) {
      return next('/unauthorized');
    }

  }

  next();
})

export default router