const filters = {
    displayPercent: function (value) {
        return parseInt(value) + '%';
    },
    displayAccountActive: function (value) {
        var text = '';
        var css = '';
        if (value == true) {
            text = 'Hoạt động';
            css = "success";
        } else {
            text = 'Khóa';
            css = "danger";
        }
        return '<span class="badge badge-' + css + '">' + text + '</span>';
    }
};

export default {
    install(Vue) {
        Vue.filter('displayPercent', filters.displayPercent);
        Vue.filter('displayAccountRole', filters.displayAccountRole);
        Vue.filter('displayAccountActive', filters.displayAccountActive);
    }
}