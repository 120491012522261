import authApi from '@/api/authApi'
import {
    setToken,
    removeToken
} from '@/common/tokenStorage';
import AuthModel from '@/models/authModel'

const state = {
    user: null,
    authenticated: false,
    isLogout: false,
}


// getters
const getters = {
    user: state => state.user,
    authenticated: state => state.authenticated,
    isLogout: state => state.isLogout,
}

// actions
const actions = {

    login({
        dispatch,
        commit
    }, payload) {
        commit('login', payload);
        authApi.login(payload).then((response) => {
            commit('login', response);
            dispatch("receiveUser");

        })

    },
    register({
        dispatch,
        commit
    }, payload) {
        authApi.register(payload).then((response) => {
            commit('login', response);
            dispatch("receiveUser");
        })

    },
    receiveUser({
        commit
    }) {
        authApi.getInfo().then((response) => {
            commit('receiveUser', response);
        }).catch(error => {
            console.log('receiveUser error',error);
            commit('logout');  
        });
    },
    logout({
        commit
    }) {
        commit('logout');
    },
}


// mutations
const mutations = {
    login(state, auth) {
        console.log('mutation login', auth)
        //state.authenticated = true
        //state.user = new AuthModel(auth)
        setToken(auth.token)

    },
    receiveUser(state, auth) {

        console.log('receiveUser', auth)
        state.authenticated = true
        state.user = new AuthModel(auth)
    },
    logout(state) {
        state.authenticated = false
        state.user = null
        removeToken()
        state.isLogout = true;
        console.log('logout') 
        //
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}