import Vue from 'vue'
import App from './App.vue'
import {
  BootstrapVue,
  IconsPlugin
} from 'bootstrap-vue' 
 
import userHub from './common/userHub' 

import './registerServiceWorker'
import router from './router'
import store from './store'
import filters from './filters';

import './assets/scss/app.scss'

//import './../node_modules/video.js/dist/video-js.css';
 

 
Vue.use(userHub)

Vue.use(filters)
Vue.config.productionTip = false
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue, {
  BToast: {
    title: 'Thông báo',
    variant: 'success',
    autoHideDelay: 5000,
    toaster: 'b-toaster-bottom-right'
  },
})
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')