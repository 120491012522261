import http from "../common/http";


export default {
    getAudios() {
        return http.get('Audio')
    },
    getGroups() {
        return http.get('Group')
    },
    upload(file) {
        let formData = new FormData();
        formData.append('files', file);
        return http.post('upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
}