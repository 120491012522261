import sharedApi from '@/api/sharedApi'
const state = {
    loading: false,
    error: null,
    message: null,
    donvi: [],
    currentDonVi: undefined,
    nam: [],
    currentNam: (new Date()).getFullYear(),
    sDonviId: -2
}

const getters = {
    loading: state => state.loading,
    error: state => state.error,
    message: state => state.message,
    donvi: state => state.donvi,
    currentDonVi: state => state.currentDonVi,
    nam: state => state.nam,
    currentNam: state => state.currentNam,
    sDonviId: state => state.sDonviId,
}

const actions = {
    setLoading({
        commit
    }, payload) {
        commit('setloading', payload)
    },
    setSDonviId({
        commit
    }, payload) {
        commit('setSDonviId', payload)
    },
    setCurrentNam({
        commit
    }, payload) {
        commit('setCurrentNam', payload)
    },
    getDonVi({
        commit
    }) {
        sharedApi.getDonVi().then((response) => {
            commit('setDonVi', response);
        })
    },

    setCurrentDonVi({
        commit,
        state
    }, donviId) {
        var dv = state.donvi.find(m => m.id == donviId);
        commit('setCurrentDonVi', dv);
    },
    getNam({
        commit
    }) {
        sharedApi.getNam().then((response) => {
            commit('setNam', response);
        })
    },
}

// mutations
const mutations = {
    setLoading(state, payload) {
     
        state.loading = payload
    }, 
    setSDonviId(state, payload) { 
        state.sDonviId = payload
    },
    setError(state, payload) {
        state.error = payload
    },
    setMessage(state, payload) {
        state.message = payload
    },
    setDonVi(state, payload) {
        state.donvi = payload
    },
    setCurrentDonVi(state, payload) {
        state.currentDonVi = payload
    },
    setNam(state, payload) {
        state.nam = payload
    },
    setCurrentNam(state, payload) {
        state.currentNam = payload
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}