import http from "../common/http";
import {
    getToken
} from '../common/tokenStorage'


export default {

    getInfo() {
        return http.get('CurrentUser')
    },
    login(payload) {
        return http.post(`Auth/Login`, payload);
    },
    refreshToken() {
        return http.post('Auth/RefreshToken', {
            Token: getToken(),
        })
    },
};