import {
    HubConnectionBuilder,
    LogLevel
} from "@microsoft/signalr";
import {
    HUB_URL
} from './config'
export default {
    install(Vue) {



        const userHub = new Vue();
        Vue.prototype.$userHub = userHub;

        let connection = null
        let startedPromise = null
        let manuallyClosed = false

        Vue.prototype.startSignalR = (jwtToken) => {
            connection = new HubConnectionBuilder()
                .withUrl(HUB_URL,
                    jwtToken ? { accessTokenFactory: () => jwtToken } : null
                ).configureLogging(LogLevel.Information)
                .build()



            // Forward hub events through the event, so we can listen for them in the Vue components
            connection.on("Heartbeat", serverTime => console.log("Server heartbeat: " + serverTime));
            connection.on("Log", message => console.log("Server message: " + message));

            connection.on("GetWatchingStreams", (streamId, connections) => {
                //console.log('GetWatchingStreams', streamId, connections);
                userHub.$emit("GetWatchingStreams", streamId, connections);
            });

            connection.on("EventDeactivated", (streamId) => {
                 userHub.$emit("EventDeactivated", streamId);
            });

           connection.on("AccountDeactivated", (accountId) => {
                userHub.$emit("AccountDeactivated", accountId);
           });


            connection.on("Logout", (status) => {

                userHub.$emit("Logout", status);
            });
            // You need to call connection.start() to establish the connection but the client wont handle reconnecting for you!
            // Docs recommend listening onclose and handling it there.
            // This is the simplest of the strategies
            function start() {
                startedPromise = connection.start().then(function () {
                    console.log('connection started', connection);
                    connection.invoke('VerifyConnection')

                }).catch(err => {
                    console.error('Failed to connect with hub', err)
                    return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000))
                })
                return startedPromise
            }
            connection.onclose(() => {
                console.log('Hub on close', manuallyClosed)
                if (!manuallyClosed) start();
            })


            // Start everything
            manuallyClosed = false
            start()
        }

        Vue.prototype.stopSignalR = () => {
            if (!startedPromise) return

            manuallyClosed = true
            return startedPromise
                .then(() => connection.stop())
                .then(() => { startedPromise = null })
        }

        // Provide methods for components to send messages back to server
        // Make sure no invocation happens until the connection is established
        userHub.getWatchingStreams = (streamId) => {
            if (!startedPromise) return

            return startedPromise
                .then(() => connection.invoke('GetWatchingStreams', streamId))
                .catch(console.error)
        }
        userHub.watchStream = (streamId) => {
            if (!startedPromise) return

            return startedPromise
                .then(() => connection.invoke('WatchStream', streamId))
                .catch(console.error)
        }
        userHub.unWatchStream = (streamId) => {
            if (!startedPromise) return

            return startedPromise
                .then(() => connection.invoke('UnWatchStream', streamId))
                .catch(console.error)
        }
    }
};