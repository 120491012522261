<template>
  <div class="app-header bg-header">
    <div class="container-fluid">
      <b-navbar toggleable="lg" type="dark">
        <b-navbar-brand
          :to="{ name: 'Home' }"
          class="d-flex align-items-center"
        >
          <img src="@/assets/images/logo-corona.png" height="30" />
        </b-navbar-brand>

        <b-navbar-nav id="navbar-menu" class="mx-auto">
          <!-- <b-nav-item :to="{ name: 'Event' }">
            <b-icon icon="calendar-event"></b-icon>
            <span> Sự kiện Live </span>
          </b-nav-item> -->
          <b-nav-item :to="{ name: 'MyEvent' }">
            <b-icon icon="bookmark-star"></b-icon>
            <span> Your event </span>
          </b-nav-item>
          <b-nav-item :to="{ name: 'Vod' }">
            <b-icon icon="play-circle"></b-icon>
            <span> VOD </span>
          </b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav id="navbar-user">
          <nav-bar-audio></nav-bar-audio>
          <b-nav-item-dropdown right no-caret class="navbar-user">
            <template #button-content>
              <b-icon icon="person-circle"></b-icon>
              <span class="username ml-3">{{ user.name }}</span>
            </template>

            <b-dropdown-item>
              <b-icon icon="person-circle"></b-icon>
              {{ user.name }}
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'ChangePassword' }"
              >Change password</b-dropdown-item>
            
            <b-dropdown-item @click="logout">Logout</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-navbar>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/common/tokenStorage";
import NavBarAudio from "./NavBarAudio.vue";
export default {
  name: "app-header",
  data() {
    return {
      openProfileNav: false,
      search: "",
    };
  },
  components: {
    NavBarAudio,
  },
  created() {
    console.log("startSignalR");
    this.startSignalR(getToken());

    this.$userHub.$on("Logout", (status) => {
      console.log("Logout", status);
      this.logout();
    });

    this.$userHub.$on("AccountDeactivated", this.onAccountDeactivated);
  },
  mounted() {},
  destroyed() {
    this.$userHub.$off("Logout");
  },
  filters: {},
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
  },
  methods: {
    logout() {
      //this.$bvToast.toast(`Đăng xuất Tài khoản`);
      this.stopSignalR();
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("auth/receiveUser");
    },
    onAccountDeactivated(accountId) {
      if (this.user.id == accountId) {
        this.logout();
      }
    },
  },
};
</script>

<style>
</style>
