<template>
  <div class="login-page">
    <b-container class="py-5" fluid>
      <b-row>
        <b-col md="4" offset-md="4">
          <div class="logo">
            <div class="mb-3">
              <img src="@/assets/images/logo-corona.png" height="80" />
            </div> 
          </div>
          <div class="form">
            <b-form @submit.prevent="login" class="form">
              <div v-if="errors.length > 0">
                <b-alert
                  show
                  variant="danger"
                  dismissible
                  v-for="(error, idx) in errors"
                  :key="idx"
                  >{{ error }}</b-alert
                >
              </div>

              <b-input-group>
                <b-input-group-prepend is-text>
                  <b-icon icon="person"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  placeholder="Tên đăng nhập"
                  required
                  v-model="form.username"
                ></b-form-input>
              </b-input-group>

              <b-input-group>
                <b-input-group-prepend is-text>
                  <b-icon icon="key"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  placeholder="Mật khẩu"
                  v-model="form.password"
                  type="password"
                  required
                ></b-form-input>
              </b-input-group>

              <b-input-group>
                <b-input-group-prepend is-text>
                  <b-icon icon="fullscreen"></b-icon>
                </b-input-group-prepend>

                <div class="input-captcha">
                  <img :src="captchaUrl" />

                  <b-form-input
                    placeholder="Captcha"
                    v-model="form.captcha"
                    autocomplete="off"
                    required
                  ></b-form-input>
                </div>
                <b-input-group-append is-text>
                  <b-button
                    type="button"
                    @click="setTimestamp"
                    pill
                    variant="primary"
                  >
                    <b-icon icon="arrow-counterclockwise"></b-icon
                  ></b-button>
                </b-input-group-append>
              </b-input-group>
              <b-form-group class="mt-5">
                <button
                  type="submit"
                  class="btn btn-primary btn-block rounded-pill btn-lg"
                >
                  {{ loginText }}
                </button>
              </b-form-group>
            </b-form>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { getToken } from "@/common/tokenStorage";
import { CAPTCHA_URL } from "@/common/config";
import authApi from "@/api/authApi";

export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
        captcha: "",
        force: false,
        clientId: "",
      },
      errors: [],
      loading: false,
      loginText: "Login",
      timestamp: 0,
    };
  },
  created() {
    this.setTimestamp();
    this.form.clientId = (Math.random() + 1).toString(36).substring(6);

    var token = getToken();
    if (token != null && token.length > 0) {
      this.loading = true;
    }
  },
  watch: {
    islogout(val) {
      if (val) {
        this.loading = false;
      }
    },
  },
  computed: {
    islogout() {
      return this.$store.getters["auth/islogout"];
    },
    captchaUrl() {
      return `${CAPTCHA_URL}/${this.form.clientId}?v=${this.timestamp}`;
    },
  },
  methods: {
    login() {
      this.loading = true;
      this.errors = [];
      this.loginText = "Login";

      authApi
        .login(this.form)
        .then((response) => {
          this.$store.commit("auth/login", response);
          this.$store.dispatch("auth/receiveUser");

          this.loading = false;
          this.form.force = false;
        })
        .catch((error) => {
          this.loading = false;
          //this.errors = [];
          if (error.message == "The account is already logged into another device") {
            this.loginText =
              "Login and Sign out of logged in devices";
            this.form.force = true;
          } else {
            this.form.captcha = "";
          }
          this.errors.push(error.message);
          this.setTimestamp();
        });
    },
    setTimestamp() {
      this.timestamp = new Date().getTime();
    },
  },
};
</script>

<style>
</style>