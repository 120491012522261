import {
    API_URL
} from './config'
import {
    getToken
} from './tokenStorage'
import authApi from '../api/authApi'
import store from '../store/index'
import axios from 'axios'


let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched(access_token) {
    subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber(callback) {
    subscribers.push(callback)
}


const request = axios.create({
    baseURL: API_URL,
});




request.interceptors.request.use(
    config => {

        store.commit('shared/setLoading', true);
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        /*
          const url = config.url.split('/');
    
          if (url[url.length - 1] === 'login' || url[url.length - 1] === 'refreshtoken') {
              delete config.headers.Authorization;
          }
          */

        return config;
    },
    error => Promise.reject(error.response.data.error),
);


request.interceptors.response.use((response) => {
    store.commit('shared/setLoading', false);
    return response.data;
}, (error) => {

    store.commit('shared/setLoading', false);

    const {
        config,
        response
    } = error
    const originalRequest = config


    if (response && response.status === 401) {
        if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true
            if (response.data == 'token expired') {
                authApi.refreshToken().then((response) => {
                    store.commit('auth/login', response);
                    isAlreadyFetchingAccessToken = false
                    onAccessTokenFetched(response.token)
                })
            } else {
                authApi.loginGuest().then((response) => {
                    store.commit('auth/login', response);
                    isAlreadyFetchingAccessToken = false
                    onAccessTokenFetched(response.token)
                })
            }

        }

        const retryOriginalRequest = new Promise((resolve) => {
            addSubscriber(access_token => {
                originalRequest.headers.Authorization = `Bearer ${access_token}`;
                resolve(request(originalRequest))
            })
        })
        return retryOriginalRequest
    }
    //fix tam code error ntn - cần defined rõ ràng hơn
    console.log('error.response', error)
    if (!error.response) {
        return Promise.reject({
            message: 'Không kết nối được với máy chủ',
            code: 404
        })
    }

    return Promise.reject(error.response.data.error)

});
export default request;