<template>
  <div>
    <div class="alert alert-danger">

      You don't have access to this function
    </div>
  </div>
</template>

<script>
export default {
  name: "UnAuthorized",
  components: {},
};
</script>
