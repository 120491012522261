<template>
  <b-nav-text class="py-0 mr-3" v-if="audios.length > 0">
    <b-button pill variant="primary" @click="toggleSound()" class="btn-icon">
      <b-icon :icon="playing ? 'volume-up' : 'volume-mute'"></b-icon>
    </b-button>
    <audio
      hidden
      ref="audio"
      :src="audios[0].filePath"
      @ended="playNext"
      preload
      id="audio"
      muted
    ></audio>
  </b-nav-text>
</template>

<script>
import sharedApi from "@/api/sharedApi";
export default {
  data() {
    return {
      audios: [],
      playing: false,
      playIdx: 0,
    };
  },
  components: {},
  created() {
    this.getAudios();
  },
  mounted() {
    //this.$refs.player.addEventListener("ended", this.playNext, false);
    console.log("player", this.$refs.audio);
  },
  filters: {},
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
  },
  methods: {
    toggleSound() {
      let audio = this.$refs.audio;
      if (audio.paused && !this.playing) {
        console.log("play it");
        audio.volume = 1;
        audio.muted = false;
        audio.play();
        this.playing = true;
      } else {
        console.log("pause it");
        audio.pause();
        this.playing = false;
      }
    },
    getAudios() {
      sharedApi.getAudios().then((response) => {
        this.audios = response;
        //this.setupPlayer();
        //this.play();
      });
    },
    setupPlayer() {},
    playNext() {
      let nextIdx = this.playIdx + 1;
      if (nextIdx >= this.audios.length) {
        nextIdx = 0;
      }
      console.log("playNext", nextIdx);
      this.playIdx = nextIdx;
      this.play();
    },
    play() {
      var player = this.$refs.audio;
      var url = this.audios[this.playIdx].filePath;
      console.log("audio url", player, url);
      player.src = url;
      player.load();
      player.play();
      this.playing = true;
    },
  },
};
</script>

<style>
</style>
